import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import api from "../services/api";
import auth from "../services/auth";
import PageHeadline from "../elements/PageHeadline";
import Translator, { __ } from "services/Translator";

export default function ProvablyFair(props) {
  const params = useParams();
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "provably_fair",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <div className="container">
      <br />
      <br />
      <PageHeadline page_name={__("Provably Fair")} />
      <br />
      <div className="privacy_policy">
        {/* <h1 className="page_title">
          {pageContent && pageContent.content_data.page_title}
        </h1> */}

        <p className="paragraph desc">
          {pageContent && renderHTML(pageContent.content_data.content)}
        </p>
      </div>
    </div>
  );
}
