import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import Dashboard from "elements/dashboard_elements/NftHolders/tabs/Dashboard";
import Analytics from "elements/dashboard_elements/NftHolders/tabs/Analytics";
import ls from "services/ls";


export default function AuthedPages(props) {
  const [activeTab, setActiveTab] = useState("dashboard");

  const disconnect = async () => {
    await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [{eth_accounts: {}}]
  })
    props.setMetamaskConnected(false);
    ls.set("metamaskConnected", false);
  };
  return (
    <>
      <ul
        class="nav nav-pills mb-3 analytics_tabs"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            onClick={() => setActiveTab("dashboard")}
            className={
              activeTab === "dashboard" ? "nav-link active" : "nav-link"
            }
            type="button"
            role="tab"
          >
            {__("Dashboard")}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            onClick={() => setActiveTab("analytics")}
            className={
              activeTab === "analytics" ? "nav-link active" : "nav-link"
            }
            type="button"
            role="tab"
          >
            {__("Analytics")}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            onClick={() => disconnect()}
            className="nav-link"
            type="button"
            role="tab"
          >
            {__("Disconnect")}
          </button>
        </li>
      </ul>

      {activeTab === "dashboard" && <Dashboard {...props} />}
      {activeTab === "analytics" && <Analytics {...props} />}
    </>
  );
}
