import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Button, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import api from "services/api";
import ReactApexChart from "react-apexcharts";
import Translator, { __ } from "services/Translator";

export default function Dashboard(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,
  };

  const [analyticsData, setAnalyticsData] = useState({});

  const getAnalyticsData = async () => {
    var response = await api.get("/nft/analyticsdata");
    if (response.status === 200) {
      console.log(response.data);
      setAnalyticsData(response.data);
    }
  };

  const getBetPerCryptoData = async () => {
    var response = await api.get("/nft/analytics/betpercryptodata");
    if (response.status === 200) {
      console.log(response.data);
      setBetPerCryptoData(response.data);
    }
  };

  const getPerRegionData = async () => {
    var response = await api.get("/nft/analytics/perregiondata");
    if (response.status === 200) {
      console.log(response.data);
      setPerRegionData(response.data);
    }
  };
  const getMostPlayedGamesData = async () => {
    var response = await api.get("/nft/analytics/mostplayedgamesdata");
    if (response.status === 200) {
      console.log(response.data);
      setMostPlayedGamesData(response.data);
    }
  };

  const [perRegionData, setPerRegionData] = useState(null);
  const [perRegionChart, setPerRegionChart] = useState(null);
  const [mostPlayedGamesData, setMostPlayedGamesData] = useState(null);
  const [mostPlayedGamesChart, setMostPlayedGamesChart] = useState(null);

  const [betPerCryptoData, setBetPerCryptoData] = useState(null);
  const [betPerCryptoChart, setBetPerCryptoChart] = useState(null);

  useEffect(() => {
    //Initialize with sample data
    var chartData = {
      series: [44, 55, 13, 33],
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: true,
        },
        labels: ["Comedy", "Action", "SciFi", "Drama", "Horror"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    };

    if (betPerCryptoData) {
      chartData.options.labels = Object.keys(betPerCryptoData.wagerData).map(
        (item) => item
      );
      chartData.series = Object.entries(betPerCryptoData.wagerData).map(
        (item) => item[1]
      );
      chartData.options.colors = ["#cbc06d", "lightgreen"];
      setBetPerCryptoChart(chartData);
    }
  }, [betPerCryptoData]);

  useEffect(() => {
    //Initialize with sample data
    var chartData = {
      series: [
        {
          name: "2013",
          data: [28, 29, 33, 36, 32, 32, 33, 33, 36, 32, 32, 33],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#cbc06d"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "History",
          align: "left",
        },
        grid: {
          borderColor: "transparent",
          row: {
            colors: ["#333333", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Wagers",
          },
          // min: 5,
          // max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };

    if (perRegionData) {
      chartData.options.title.text = "Players";
      chartData.options.xaxis.categories = perRegionData.data.map(
        (item) => item.player_country
      );
      chartData.options.xaxis.title.text = "Country";
      chartData.series = [
        {
          name: perRegionData.currentPeriodName,
          data: perRegionData.data.map((item) => item.cnt),
        },
        {
          name: perRegionData.previousPeriodName,
          data: perRegionData.data.map((item) => item.prevCnt),
        },
      ];
      chartData.options.colors = ["#cbc06d", "lightgreen"];
      setPerRegionChart(chartData);
    }
  }, [perRegionData]);

  useEffect(() => {
    //Initialize with sample data
    var chartData = {
      series: [
        {
          name: "2013",
          data: [28, 29, 33, 36, 32, 32, 33, 33, 36, 32, 32, 33],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#cbc06d"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "History",
          align: "left",
        },
        grid: {
          borderColor: "transparent",
          row: {
            colors: ["#333333", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          title: {
            text: "Game",
          },
        },
        yaxis: {
          title: {
            text: "Bets",
          },
          // min: 5,
          // max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };

    if (mostPlayedGamesData) {
      chartData.options.title.text = "Most played games";
      chartData.options.xaxis.categories = mostPlayedGamesData.data.map(
        (item) => item.game_id
      );
      chartData.options.xaxis.title.text = "Game ID";
      chartData.series = [
        {
          name: mostPlayedGamesData.currentPeriodName + "(Rounds played)",
          data: mostPlayedGamesData.data.map((item) => item.bet_count),
        },
        {
          name: mostPlayedGamesData.previousPeriodName + "(Rounds played)",
          data: mostPlayedGamesData.data.map((item) => item.prev_bet_count),
        },
      ];
      chartData.options.colors = ["#cbc06d", "lightgreen", "red", "yellow"];
      console.log("Chart", chartData);
      setMostPlayedGamesChart(chartData);
    }
  }, [mostPlayedGamesData]);

  useEffect(() => {
    // getNfts();
    getBetPerCryptoData();
    getAnalyticsData();
    getPerRegionData();
    getMostPlayedGamesData();
  }, []);

  return (
    <div className="backend">
      <div className="dashboard_content">
        <>
          <div className="analytics">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-6">
                <div className="block position_relative margin_bottom_15">
                  <i class="far fa-user user_absoulate"></i>
                  <div className="text_area">
                    <h6 className="text-white">{__("Registered players")}</h6>
                    <h2 className="text-white bold">
                      {analyticsData?.this_period_registrations}
                    </h2>
                    <div className="paragraph no_margin">
                      {analyticsData?.registrations_change < 0 ? "" : "+"}{" "}
                      {analyticsData?.registrations_change?.toFixed(0)}% from
                      last month
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-6">
                <div className="block margin_bottom_15">
                  <div className="text_area">
                    <h6 className="text-white">{__("Game rounds played")}</h6>
                    <h2 className="text-white bold">
                      {analyticsData?.this_period_game_rounds}
                    </h2>
                    <div className="paragraph no_margin">
                      {analyticsData?.game_rounds_change < 0 ? "" : "+"}{" "}
                      {analyticsData?.game_rounds_change?.toFixed(0)}% from last
                      month
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">
                <div className="block margin_bottom_15">
                  <div className="chart_header">
                    <h6 className="text-white">{__("Crypto Popularity")}</h6>
                  </div>

                  <br />

                  <div className="graph_img">
                    {!!betPerCryptoChart && (
                      <ReactApexChart
                        options={betPerCryptoChart.options}
                        series={betPerCryptoChart.series}
                        type="donut"
                        width={380}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">
                <div className="block margin_bottom_15">
                  <div className="chart_header">
                    <h6 className="text-white">{__("Players per region")}</h6>
                    <div className="list with_flex"></div>
                  </div>

                  <br />

                  <h2 className="text-white">
                    Total: {analyticsData?.this_period_registrations}
                    <span className="carete_up">
                      {analyticsData?.registrations_change < 0 ? (
                        <i class="fas fa-caret-down"></i>
                      ) : (
                        <i class="fas fa-caret-up"></i>
                      )}
                    </span>
                    <span className="paragraph white">
                      {analyticsData?.registrations_change < 0 ? "" : "+"}{" "}
                      {analyticsData?.registrations_change?.toFixed(0)}% from
                      last month
                    </span>
                  </h2>
                  <div className="graph_img">
                    {!!perRegionChart && (
                      <ReactApexChart
                        options={perRegionChart.options}
                        series={perRegionChart.series}
                        type="line"
                        height={350}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className="row">
              {/*<div className="col-md-12 col-lg-12 col-xl-6">
                <div className="block margin_bottom_15">
                  <div className="chart_header">
                    <h6 className="text-white">Total amount of winnings</h6>
                    <div className="list with_flex">
                      <Link hrefLang={Translator.getSelectedLanguage().code} to="#" className="paragraph no_margin white">
                        Global
                      </Link>
                      <Link hrefLang={Translator.getSelectedLanguage().code} to="#" className="paragraph no_margin">
                        Monthly{" "}
                      </Link>
                      <Link hrefLang={Translator.getSelectedLanguage().code} to="#" className="paragraph no_margin">
                        Weekly{" "}
                      </Link>
                      <Link hrefLang={Translator.getSelectedLanguage().code} to="#" className="paragraph no_margin">
                        Daily{" "}
                      </Link>
                    </div>
                  </div>

                  <br />
                  <br />
                  <div className="wining_blocks small_block">
                    <div className="left_area">
                      <Button className="wining_btn">Wins</Button>
                      <h6 className="text-white">Total amount of winnings</h6>
                      <div className="paragraph">
                        <i class="fas fa-check"></i> Updated on January 25, 2022
                      </div>
                    </div>
                    <div className="insight_area">
                      <img
                        src={
                          require("assets/images/dashboard/icon/green-graph2.png")
                            .default
                        }
                      />
                      <div className="text_area">
                        <h5 className="text-white bold">+2%</h5>
                        <div className="paragraph no_margin">Insights</div>
                      </div>
                    </div>
                  </div>
                  <div className="wining_blocks small_block">
                    <div className="left_area">
                      <Button className="wining_btn lose">Loses</Button>
                      <h6 className="text-white">Total amount of winnings</h6>
                      <div className="paragraph">
                        <i class="fas fa-check"></i> Updated on January 25, 2022
                      </div>
                    </div>
                    <div className="insight_area">
                      <img
                        src={
                          require("assets/images/dashboard/icon/loss.png")
                            .default
                        }
                      />
                      <div className="text_area">
                        <h5 className="text-white bold">-2%</h5>
                        <div className="paragraph no_margin">Insights</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>*/}
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="block margin_bottom_15">
                  <div className="chart_header with_elapsis">
                    <h6 className="text-white">{__("Most played games")}</h6>
                  </div>
                  <br />
                  <div>
                    {!!mostPlayedGamesChart && (
                      <ReactApexChart
                        options={mostPlayedGamesChart.options}
                        series={mostPlayedGamesChart.series}
                        type="line"
                        height={350}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
