import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";

const GameImage = ({ src, className }) => {
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(src);

  useEffect(() => {
    setGameSnapshotUrl(src);
  }, [src]);

  const setDefaultImage = () => {
    setGameSnapshotUrl(require("assets/images/games/default.png").default);
  };

  return <img className={className} src={gameSnapshotUrl} onError={setDefaultImage} alt={__("Game Thumb")} />;
};

export default GameImage;
