import React, { useState, useEffect, useCallback } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import api from "services/api";
import contractsAbi from "configs/contract_abi.json";
import Translator, { __ } from "services/Translator";

class Metamask {
  constructor() {
    // https://web3js.readthedocs.io/en/v1.7.3/web3-eth-personal.html#sign
    // https://www.quicknode.com/guides/web3-sdks/how-to-build-a-one-click-sign-in-using-metamask-with-php-s-laravel
    this.account = "";
    this.contractAddress = "0xcaA37C3822Cc2e7ABD2Ae2FE7EE85F053220A650";
    this.connected = false;
    this.nfts = [];
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async startApp() {
    var provider = await detectEthereumProvider();
    if (provider) {
      // If the provider returned by detectEthereumProvider is not the same as
      // window.ethereum, something is overwriting it, perhaps another wallet.
      if (provider !== window.ethereum) {
        alert(__("Do you have multiple wallets installed?"));
      }
      // Access the decentralized web!
      else {
        // this.connectWithMetamask();
      }
    } else {
      alert(__("Please install MetaMask!"));
    }
  }

  async connectWithMetamask(provider) {
    await this.startApp();
    var provider = await detectEthereumProvider();
    await provider.request({ method: "eth_requestAccounts" });
    const web3 = new Web3(provider);

    var accounts = await web3.eth.getAccounts();
    console.log(accounts);
    const account = accounts[0];
    this.account = account;
    var balance = await web3.eth.getBalance(account);
    console.log(balance);

    var contractAddress = this.contractAddress;
    var contract = new web3.eth.Contract(contractsAbi, contractAddress);
    contract.defaultAccount = account;

    var messages = [
      "His shirt is covered in sweat.",
      "I saw a big dog lying in front of Mr Hill's house.",
      "I made a list of people I wanted to invite to my party.",
      "This town isn't big enough for both of us.",
      "Toothpaste will help keep your teeth clean.",
      "He often wished he was born in a time before social media.",
      "She's a hypercritical monster.",
      "It's around three hours long.",
      "He snuck into the house after midnight to investigate.",
      "My mom made a milkshake with frozen bananas and chocolate sauce.",
      "I could cook dinner.",
      "It was a tough time.",
      "The big, yellow bus came hurtling down the street.",
      "I'd like to check your blood pressure.",
      "In Iceland, the sun didn't set until 11 pm, even it though it rose at 3 in the morning.",
      "Hand me the hammer!",
      "I don't mess around with diets; when I'm hungry, I eat.",
      "As luck would have it I found a pen at the bottom of my bag.",
      "What do you mean he moved to Bogota?",
    ];

    var message =
      "Please sign an arbitrary message so we can verify your account authenticity. \n\n'" +
      messages[this.getRandomInt(0, messages.length - 1)] +
      "'";

    // message = web3.utils.sha3(message);
    console.log(message);
    var signature = await web3.eth.personal.sign(message, account);
    var data = {
      wallet: account,
      message,
      signature,
    };
    var response = api.post("/nft/login/metamask", data);

    return response;
  }
}

export default new Metamask();
