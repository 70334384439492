import React, { useState, useEffect } from "react";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../elements/PageHeadline";
import api from "services/api";

export default function PrivacyPolicy(props) {
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "privacy_policy",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <div className="container">
      <br />
      <br />
      <PageHeadline page_name={__("Privacy Policy")} />
      <div className="privacy_policy">
        {/* <h1 className="page_title">
        {pageContent && pageContent.content_data.page_title}
      </h1> */}

        <p className="paragraph desc">
          {pageContent && renderHTML(pageContent.content_data.content)}
        </p>
      </div>
    </div>
  );
}
